import * as React from "react";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

import { Flex } from "./Box";
import Navbar from "./Navbar/navbar";
import { Text } from "./Text";

const MainWrapper = styled.div`
  display: flex;
  max-width: 1215px;
  margin: 0 auto;
`;

const ButtonsWrapper = styled.a<{ activeIndex: boolean }>`
  gap: 16px;
  width: max-content;
  margin-left: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #f3f3f3;
  border-bottom: ${({ activeIndex }) =>
    activeIndex ? "1px solid #5387ed" : "none"};
  :hover {
    color: #c9c9c9;
  }
`;

const navbarData = [
  { title: "Home", href: "/" },
  { title: "Our Team", href: "/our-team/" },
  { title: "Contact", href: "/contact/" },
];

const Header = () => {
  const isMobile = useMediaQuery("(min-width:900px)");

  const getCurrentPath = () => {
    if (typeof window !== "undefined") {
      return window?.location?.pathname;
    }
  };
  console.log(getCurrentPath());

  return (
    <MainWrapper>
      <Navbar
        navbarContent={
          isMobile && (
            <Flex width="100%" style={{ gap: "25px" }}>
              {navbarData.map((data) => {
                return (
                  <ButtonsWrapper
                    activeIndex={getCurrentPath() === data.href}
                    key={data.title}
                    href={data.href}
                  >
                    <Text>{data.title}</Text>
                  </ButtonsWrapper>
                );
              })}
            </Flex>
          )
        }
        sidebarContent={
          <Flex width="100%" flexDirection="column" style={{ gap: "25px" }}>
            {navbarData.map((data) => {
              return (
                <ButtonsWrapper
                  activeIndex={getCurrentPath() === data.href}
                  key={data.title}
                  href={data.href}
                >
                  <Text>{data.title}</Text>
                </ButtonsWrapper>
              );
            })}
          </Flex>
        }
      />
    </MainWrapper>
  );
};

export default Header;
