import React from "react";
import Layout from "./src/components/layout";
import ModalProvider from "./src/components/Modal/ModalContext";

const wrapPageElement = ({ element, props }) => {
  return (
    <ModalProvider>
      <Layout {...props}>{element}</Layout>
    </ModalProvider>
  );
};

export { wrapPageElement };
