import * as React from "react";
import styled from "styled-components";
import UseAnimations from "react-useanimations";
import menu2 from "react-useanimations/lib/menu2";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Flex } from "../Box";
import ALSLogoIcon from "../../svg/ALSLogo";

const MainWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 32px;
  z-index: 99999;
  @media (max-width: 900px) {
    padding: 25px 28px;
  }
`;

const NavbarContent = styled(Flex)`
  width: 100%;
  z-index: 55;
  justify-content: space-between;
  align-items: center;
`;

const SidebarContent = styled(Flex)`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background: #171c32;
  z-index: 5;
  width: 100%;
  height: 100vh;
  padding: 124px 32px;
`;

const LogoWrapper = styled.a`
  display: flex;
  cursor: pointer;
  margin-right: 5vw;
  z-index: 11;
  @media (max-width: 900px) {
    margin-right: 4vw;
  }
`;

const HamburgerMenuWrapper = styled(Flex)`
  z-index: 11;
  margin-right: 1vw;
`;

interface INavProps {
  navbarContent: React.ReactNode;
  sidebarContent?: React.ReactNode;
}

const Navbar: React.FunctionComponent<INavProps> = ({
  navbarContent,
  sidebarContent,
}) => {
  const hamburgerDisplay = useMediaQuery("(max-width:900px)");
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <MainWrapper>
      <LogoWrapper href="/">
        <ALSLogoIcon />
      </LogoWrapper>
      <NavbarContent>{navbarContent}</NavbarContent>
      {menuOpen && <SidebarContent>{sidebarContent}</SidebarContent>}
      {hamburgerDisplay && sidebarContent && (
        <HamburgerMenuWrapper>
          <UseAnimations
            animation={menu2}
            strokeColor="#fff"
            size={48}
            speed={2.5}
            onClick={() => toggleMenu()}
          />
        </HamburgerMenuWrapper>
      )}
    </MainWrapper>
  );
};

export default Navbar;
