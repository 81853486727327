import React from "react";

const DiscordIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ion:logo-discord">
        <path
          id="Vector"
          d="M21.75 3.47164C21.7441 2.85492 21.4953 2.26539 21.0576 1.83086C20.6199 1.39634 20.0286 1.15184 19.4119 1.15039L4.57687 1.10352C3.96076 1.10364 3.36983 1.34806 2.93365 1.78319C2.49746 2.21832 2.25161 2.80865 2.25 3.42477V18.7285C2.25 20.0082 3.29437 20.9785 4.57687 20.9785H17.25L16.6406 18.916L21.75 23.6035V3.47164ZM15.218 15.8134C15.218 15.8134 14.8092 15.3263 14.468 14.9077C15.9558 14.4887 16.5234 13.5723 16.5234 13.5723C16.1148 13.8433 15.6769 14.0674 15.218 14.2402C14.6881 14.4602 14.1364 14.6235 13.5722 14.7273C12.6 14.9058 11.603 14.902 10.6322 14.716C10.0611 14.61 9.50221 14.4466 8.96391 14.2285C8.67914 14.1194 8.40223 13.9909 8.13516 13.8437C8.10094 13.8212 8.06719 13.8099 8.03297 13.787C8.01 13.7757 7.99875 13.7645 7.98609 13.7645C7.78172 13.651 7.66828 13.5718 7.66828 13.5718C7.66828 13.5718 8.21297 14.4666 9.65484 14.8965C9.31406 15.3268 8.89406 15.8251 8.89406 15.8251C6.38578 15.7459 5.43234 14.1155 5.43234 14.1155C5.43234 10.5034 7.06687 7.57086 7.06687 7.57086C8.70141 6.35914 10.245 6.39336 10.245 6.39336L10.3584 6.5293C8.31516 7.1068 7.38469 8.00117 7.38469 8.00117C7.38469 8.00117 7.63406 7.86523 8.05406 7.68383C9.26859 7.1518 10.2338 7.01586 10.6322 6.97086C10.6958 6.95764 10.7604 6.94996 10.8253 6.94789C11.5859 6.8492 12.3556 6.84164 13.118 6.92539C14.315 7.0629 15.4748 7.42805 16.5347 8.00117C16.5347 8.00117 15.638 7.15039 13.7081 6.57383L13.867 6.39289C13.867 6.39289 15.4219 6.35867 17.0456 7.57227C17.0456 7.57227 18.6802 10.5048 18.6802 14.117C18.6802 14.1038 17.7262 15.7341 15.218 15.8134Z"
          fill="#f3f3f3"
        />
        <path
          id="Vector_2"
          d="M9.94004 10.5723C9.29316 10.5723 8.78223 11.1273 8.78223 11.8177C8.78223 12.5082 9.30441 13.0632 9.94004 13.0632C10.5869 13.0632 11.0979 12.5087 11.0979 11.8177C11.1096 11.1259 10.5869 10.5723 9.94004 10.5723ZM14.0829 10.5723C13.436 10.5723 12.925 11.1273 12.925 11.8177C12.925 12.5082 13.4472 13.0632 14.0829 13.0632C14.7302 13.0632 15.2407 12.5087 15.2407 11.8177C15.2407 11.1268 14.7189 10.5723 14.0829 10.5723Z"
          fill="#f3f3f3"
        />
      </g>
    </svg>
  );
};

export default DiscordIcon;
