import styled from "styled-components";
import { color, fontSize, space, typography } from "styled-system";
import { TextProps } from "./types";

const Text = styled.div<TextProps>`
  ${color};
  ${fontSize};
  line-height: 27px;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${space}
  ${typography}
`;

export default Text;
